<template>
    <div id="test6">
        <div class="crumbs">人事管理 / 人员考勤记录</div>
        <div class="center">
            <div class="table">
                <div class="center_top">
                    <span>
                        <el-form :inline="true" class="demo-form-inline">
                            <el-form-item label="姓名查询">
                                <el-input placeholder="请输入人员姓名" v-model="keywords"></el-input>
                            </el-form-item>
                            <el-form-item label="打卡类型">
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="开始日期">
                                <el-date-picker v-model="value1" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="结束日期">
                                <el-date-picker v-model="value2" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit" size="medium">查询</el-button>
                            </el-form-item>
                        </el-form>
                    </span>
                </div>
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                    <el-table-column prop="app_user_id" label="编号" width="50" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="user_name" label="姓名" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="user_phone" label="手机号码" :align="'center'">
                    </el-table-column>
                    <el-table-column label="打卡类型" :align="'center'">
                        <template slot-scope="{row}">
                            <div>
                                {{ row.type == 1 ? "上班打卡" : "下班打卡" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="打卡时间" prop="time" :align="'center'">
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <span style="float: right; margin-top: 20px;margin-right: 20px;">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="limit"
                        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
                    </el-pagination>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { staffPunchLog } from "../request/api"
export default {
    name: 'DemoTest6',
    data() {
        return {
            tableData: [],
            page: 1,//当前页
            limit: 10,//一页展示几条
            total: 0,
            options: [{
                value: '',
                label: '全部'
            }, {
                value: 1,
                label: '上班打卡'
            }, {
                value: 2,
                label: '下班打卡'
            }],
            value: '',
            value1: '',
            value2: '',
            keywords: ""
        };
    },
    mounted() {
        this.getstaffPunchLog()
    },
    methods: {
        async getstaffPunchLog() {
            let res = await staffPunchLog({
                page: this.page,
                limit: this.limit,
                type: this.value,
                keywords: this.keywords,
                sdate: this.value1,
                edate: this.value2
            })


            this.total = res.data.counts
            this.tableData = res.data.list
        },
        onSubmit() {
            this.getstaffPunchLog()

        },
        async handleSizeChange(val) {
            this.limit = val
            this.getstaffPunchLog()

        },
        async handleCurrentChange(val) {
            this.page = val
            this.getstaffPunchLog()
        },


    },
};
</script>

<style lang="less" scoped>
#test6 {
    width: 100%;
    height: 100vh;

}

.crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
}

.center {
    width: 100vw;
    height: calc(100% - 31px);
    // background-color: #000;
    margin: auto;




    .center_top {
        padding-top: 10px;
        padding-bottom: 13px;
        height: 36px;
        display: flex;
        justify-content: center;

        ::v-deep .el-input__inner {
            width: 190px;
            height: 32px;
        }
    }

    .table {
        height: calc(100vh - 100px);
        overflow: auto;
        padding: 0 10px;
    }
}
</style>